// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
// require('clipboard/dist/clipboard.min.js')


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '../stylesheets/application'
import 'bootstrap-icons/font/bootstrap-icons.css'

import './bootstrap_custom.js'
import '@fortawesome/fontawesome-free/js/all.js'
import Clipboard from 'clipboard/dist/clipboard.min';

// $(document).ready(function () {
//     new Clipboard('.btn');
//     // new ClipboardJS('.btn');
// });

var ready;
ready = function() {  
    new Clipboard('.btn');
};

$( document ).on('turbolinks:load', ready);
